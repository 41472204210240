import http from "../utils/http-client";

const login = (data) => {
    return http.post('/login', data, {
        transformResponse: [(result) => {
            const parsed = JSON.parse(result);
            localStorage.setItem('authUser', JSON.stringify(parsed));
            return parsed;
        }]
    });
}

const register = (data) => {
    return http.post('/register', data);
}

const profile = () => {
    return http.get('/user');
}

const logout = () => {
    return http.get('/logout', null, {
        transformResponse: [(result) => {
            localStorage.removeItem('authUser');
            return JSON.parse(result);
        }]
    });
}

const getAuthUser = () => {
    return JSON.parse(localStorage.getItem('authUser'));
}  

const isTokenValid = () => {
 
    const parseJwt = (token) => {
        try {
          return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
          return null;
        }
      };
      const AuthUser=JSON.parse(localStorage.getItem('authUser'))
      const decodedJwt = parseJwt(AuthUser.access_token);
      if (decodedJwt.exp * 1000 < Date.now()) {
              console.log('Token expired')
            }
            else{
                return AuthUser
            }

}


const methods = { 
    login,
    register,
    profile,
    logout,
    getAuthUser,
    isTokenValid
}

export default methods;