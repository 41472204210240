import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import authService from '../services/auth.service';
import { Container, Box } from '@mui/material';

const ProfileComponent = () => {

  const [user, setUser] = useState(null);

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      const result = await authService.profile();
      setUser(result.data)

    } catch (error) {
      toast.error(error.data.message);
    }
  }

  function getname(){
  
    return (user.name.substring(0,1));

  }
  console.log(getname)

  return (
  
    
    // <Avatar sx={{ bgcolor: deepOrange[500],position: "absolute",
    // top: 70,
    // right: 10  }} {...getname}/>
<Container maxWidth="sm" sx={{mt:50,background: '#f2f6fc'} }>

    <Box>
    <h1>Welcome to CE Dashboard {user?.name}</h1>
    </Box>
    </Container>

 
  )
}

export default ProfileComponent