import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useState, useEffect, useRef } from 'react';
import axios from "axios";
import authService from '../../services/auth.service';
import { ReactComponent as ReactLogo } from './Time.svg';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#e1f5fe',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Dashboard = ({ searchTerm }) => {
  const [cardData, setCardData] = useState([]);

  const fetchDataForInstance = async (instanceName, config) => {
    const instanceURL = "/api/instances/getcounts/" + instanceName;
    const alarmURL = "/api/instances/getalarms/" + instanceName;

    try {
      const [response1, response2] = await Promise.all([
        axios.get(instanceURL, config),
        axios.get(alarmURL, config)
      ]);

      const instanceCountData = response1.data;
      const alarmData = response2.data;

      const localTime = new Date(instanceCountData.updatedAt);
      const formatLocalTime = (date) => {
        return date.toLocaleString(undefined, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        });
      };

      return {
        instanceName: instanceCountData.instanceName,
        version: instanceCountData.version,
        totalDevices: instanceCountData.totalDevices,
        activeDevices: instanceCountData.activeDevices,
        updated: formatLocalTime(localTime),
        total_alarm: Alarmcount(alarmData[0].alerts),
        alerts: alarmData[0].alerts
      };
    } catch (error) {
      console.error(`Failed to fetch data for ${instanceName}:`, error);
      return null;
    }
  };

  const intervalRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const authUser = authService.getAuthUser();
      const token = authUser.access_token;
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      const instancelist = await axios.get('/api/instances/instancelist', config);
      const Instancelistdata = instancelist.data;

      try {
        const fetchDataPromises = Instancelistdata.map((item) =>
          fetchDataForInstance(item.instanceName, config)
        );

        const dataForAllInstances = await Promise.all(fetchDataPromises);
        const filteredData = dataForAllInstances.filter(Boolean);
        setCardData(filteredData);
      } catch (error) {
        console.error('Failed to fetch and combine data:', error);
      }
    };

    const startFetchingData = () => {
      fetchData();
      intervalRef.current = setInterval(fetchData, 600000);
    };

    startFetchingData();

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [fetchDataForInstance]); // Add fetchDataForInstance to the dependency array

  const filteredCardData = cardData.filter(card =>
    card.instanceName && card.instanceName.toLowerCase().includes(searchTerm.toLowerCase())
  ).sort((a, b) => b.total_alarm - a.total_alarm);

  const Alarmcount = (aData) => {
    if (aData[0] === 'No active alerts') {
      return 0;
    } else {
      let alarmcount = aData.length;
      return alarmcount;
    }
  };

  // Calculate time difference in minutes
  const TimeInMinutes = (updatedTime) => {
    const currentTime = new Date();
    const timeDifferenceMin = currentTime - new Date(updatedTime);
    return Math.floor(timeDifferenceMin / (1000 * 60));
  };

  // Card color based on last updated time
  const getCardColor = (updatedTime) => {
    const timeDifference = TimeInMinutes(updatedTime)
    if (timeDifference < 16) {
      return '#ffccbc'
    } else {
      return '#e50914'
    }
  };

  return (
    <Grid container spacing={2} >
      {filteredCardData.map((card, index) => (
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={index}>
          <Card style={{ margin: '10px', height: '320px', border: '1px groove #0d47a1', backgroundColor: TimeInMinutes > 16 ? getCardColor(card.updated) : '#eceff1' }}>
            <CardContent>
              <Typography variant="h5" component="h2" sx={{ color: "#01579b", fontWeight: 'bold' }}>
                {card.instanceName}
              </Typography>
              <Typography sx={{ color: "#01579b", textAlign: 'right', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}><ReactLogo style={{ marginRight: '5px', width: '20px', height: '20px' }} /> {card.updated}</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6} >
                  <Typography sx={{ fontWeight: 'bold' }}>Version</Typography>
                  <Item sx={{ bgcolor: '#37474f', color: '#e1f5fe', fontWeight: 'bold' }} >{card.version}</Item>
                </Grid>
                <Grid item xs={6} >
                  <Typography sx={{ fontWeight: 'bold' }} >Devices</Typography>
                  <Item sx={{ bgcolor: '#37474f', color: '#e1f5fe', fontWeight: 'bold' }}>{card.activeDevices}/{card.totalDevices}</Item>
                </Grid>
              </Grid>
              <Typography color="textSecondary" gutterBottom sx={{ fontWeight: 'bold' }} >
                Active Alerts [{card.total_alarm}]
              </Typography>
              <div style={{ maxHeight: '150px', overflow: 'auto', backgroundColor: '#fbe9e7', border: '1px ridge #0d47a1' }}>
                {card.alerts.map((alert, alertIndex) => (
                  <Typography
                    key={alertIndex}
                    color="#ff5722"
                    gutterBottom
                    style={{ textAlign: 'center' }}
                  >
                    {alert}
                  </Typography>
                ))}
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Dashboard;
