import './App.css';
import Navbar from './components/Navbar/Navbar';
import { Outlet } from "react-router-dom";
import AppRouter from './app.router';
import React, { useState } from 'react';





function App() {

  const [searchTerm, setSearchTerm] = useState('');


  return (
    <>
    <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
    <Outlet />
    <AppRouter searchTerm={searchTerm} />
    </>
  );
}

export default App;
