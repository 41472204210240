import React from 'react'
import {ReactComponent as ReactLogo} from './logo.svg';


export default function OrbiwiseIcon(props) {
   

    return (

        <div className="App" >
      <ReactLogo height={50} width={150}  />
    </div>
        
    )


}

