import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthGuard from './guards/auth.guard';
import Dashboard  from './components/Dashboard/Dashboard.js'

import LoginComponent from './components/login.component';
// import InstanceData from './components/Dashboard/InstanceData';
// import Customers from './pages/Customers/customer';
// import Instances from './pages/Instances/instances';
import ProfileComponent from './components/profile.component';
// import Billingreports from './pages/Billing Reports/billingreports'
import authService from './services/auth.service';

const authUser = authService.getAuthUser();




const AppRouter = ({ searchTerm }) => { 

    return (
        <Routes>
            <Route exact path='/' element={<LoginComponent />} />
     
                <Route element={<AuthGuard />}>
                <Route path='/profile' element={<ProfileComponent />} />
                <Route path='/dashboard' element={<Dashboard searchTerm={searchTerm} />} />
            </Route>
            
            <Route path='*' element={<LoginComponent />} />
            

        </Routes>
    )
}

export default AppRouter;